body {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    color: #D9D9D9;

background: #121212;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
